@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Axiforma;
  font-style: normal;
  font-weight: 350;
  src: url("../fonts/Axiforma/Kastelov - Axiforma Book.otf") format("opentype");
}
@font-face {
  font-family: Axiforma;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Axiforma/Kastelov - Axiforma Regular.otf")
    format("opentype");
}
@font-face {
  font-family: Axiforma;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Axiforma/Kastelov - Axiforma Medium.otf")
    format("opentype");
}
@font-face {
  font-family: Axiforma;
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Axiforma/Kastelov - Axiforma SemiBold.otf")
    format("opentype");
}
@font-face {
  font-family: Axiforma;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Axiforma/Kastelov - Axiforma Bold.otf") format("opentype");
}
@font-face {
  font-family: Axiforma;
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/Axiforma/Kastelov - Axiforma ExtraBold.otf")
    format("opentype");
}
@font-face {
  font-family: Axiforma;
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/Axiforma/Kastelov - Axiforma Black.otf") format("opentype");
}

body {
  background-color: #272c6a;
  margin: 0;
  font-family: "Axiforma", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
